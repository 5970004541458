<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>Tanggal <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="dateStart"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateStart" @input="menuStart = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>Alamat Asal <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>Alamat Tujuan <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>No. Pemindahan # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <v-text-field outlined flat dense background-color="white"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-tabs vertical>
            <v-tab>
              <v-icon>
                mdi-note-text
              </v-icon>
            </v-tab>
            <v-tab>
              <v-icon>
                mdi-information
              </v-icon>
            </v-tab>
            <v-tab-item>
              <v-card outlined flat class="px-2">
                <v-row>
                  <v-col cols="5">
                    <v-autocomplete
                      outlined
                      flat
                      dense
                      background-color="white"
                      placeholder="Find/Choose Account"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="7" align-self="start" class="text-right">
                    <p class="headline font-italic">
                      Asset Detail
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      disable-pagination
                      hide-default-footer
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat outlined>
                <v-card-title class="font-italic">Additional Info</v-card-title>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="4" sm="4" md="3" lg="3">
                        <v-subheader>Keterangan</v-subheader>
                      </v-col>
                      <v-col cols="8" sm="8" md="6" lg="6">
                        <v-textarea outlined flat rows="3" background-color="white"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1">
        <v-row>
          <v-col cols="12">
            <v-btn color="primary">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {};
</script>

<style></style>
